import { ref, computed, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useSystemCodes from '@/views/system-settings/system-codes/useSystemCodes'
import usePrivilegesForm from '../../privileges/privileges-form/usePrivilegesForm'
import userStoreModule from '../userStoreModule'
import router from '@/router'

export default function useUsersForm() {
  const USER_APP_STORE_MODULE_NAME = 'app-user'

  // Register module
  if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
  })

  const blankUserData = {
    operation: 'C',
    avatar: '',
    name: '',
    id_number: '',
    gender: '',
    user_type: '',
    phone: '',
    email: '',
    account_type: '',
    privilege: '',
  }

  const userData = ref(JSON.parse(JSON.stringify(blankUserData)))

  // *===============================================---*
  // *--------- OPTIONS ---------------------------------------*
  // *===============================================---*

  // callStatus()

  const genderOptions = [
    { text: 'Male', value: 'male' },
    { text: 'Female', value: 'female' },
  ]

  // *===============================================---*
  // *--------- ACTIONS ---------------------------------------*
  // *===============================================---*

  const updateAvatar = base64 => {
    //  userData.value.avatar = base64

    //  Obj caveat; doesnt update if the key is not present
    userData.value = { ...userData.value, avatar: base64 }
  }

  const removeAvatar = () => {
    // userData.value.avatar = null
    userData.value = { ...userData.value, avatar: null }
  }

  const fetchUser = async () => {
    try {
      const response = await store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      // eslint-disable-next-line prefer-destructuring
      userData.value = response.data.data[0]
    } catch (error) {
      if (error.response.status === 404) {
        userData.value = undefined
      }
    }
  }

  const createUser = async () => {
    try {
      userData.value.operation = 'C'
      const response = await store.dispatch('app-user/procUser', userData.value)
      if (!response.data.status) {
        return
      }
      router.push({ name: 'system-settings-users-list' })
    } catch (error) {
      console.error(error)
    }
  }

  const updateUser = async () => {
    try {
      // TODO: Remove user_id key
      userData.value.user_id = userData.value.id
      userData.value.operation = 'E'
      const response = await store.dispatch('app-user/procUser', userData.value)
      if (response.data.status) {
        router.push({ name: 'system-settings-users-list' })
      }
    } catch (error) {
      console.error(error)
    }
  }

  return {
    //  Data
    userData,

    //  UI
    updateAvatar,
    removeAvatar,

    //  Actions
    fetchUser,
    createUser,
    updateUser,

    //  Options
    genderOptions,
  }
}
