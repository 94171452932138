<template>
  <b-row>
    <b-col sm="6">

      <!-- Name -->
      <validation-provider
        #default="validationContext"
        name="Full Name"
        rules="required"
      >
        <b-form-group
          label="User Name"
          label-for="user-name"
        >
          <b-form-input
            id="user-name"
            v-model="userData.name"
            :state="getValidationState(validationContext)"
            placeholder="User Name"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <!-- ID Number -->
      <validation-provider
        #default="validationContext"
        name="Id Number"
        rules="digits:10"
      >
        <b-form-group
          label="ID Number"
          label-for="user-id-number"
        >
          <b-form-input
            id="user-id-number"
            v-model="userData.id_number"
            :state="getValidationState(validationContext)"
            placeholder="ID Number"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <!-- Gender -->
      <b-form-group
        label="Gender"
        label-for="user-gender"
        label-class="mb-1"
      >
        <b-form-radio-group
          id="user-gender"
          v-model="userData.gender"
          :options="genderOptions"
          :value="userData.gender"
        />
      </b-form-group>

      <!-- User  Type -->
      <validation-provider
        #default="validationContext"
        name="User Type"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="User Type"
          label-for="user-type"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="userData.user_type"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="userTypeOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="user-type"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <!-- Contact Number -->
      <validation-provider
        #default="validationContext"
        name="Contact"
        rules="required|digits:12"
      >
        <b-form-group
          label="User Contact Number"
          label-for="user-contact-number"
        >

          <b-form-input
            id="user-contact-number"
            v-model="userData.phone"
            :state="getValidationState(validationContext)"
            placeholder="User Contact Number"
          />
          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>

        </b-form-group>
      </validation-provider>

      <!-- Email -->
      <validation-provider
        #default="validationContext"
        name="Email"
        rules="required|email"
      >
        <b-form-group
          label="User Email"
          label-for="email"
        >
          <b-form-input
            id="user-email"
            v-model="userData.email"
            :state="getValidationState(validationContext)"
            placeholder="User Email"
          />

          <b-form-invalid-feedback>
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>

    <!-- Avatar -->
    <b-col sm="6">
      <b-media
        class="mb-2 align-items-end"
        :vertical-align="'end'"
      >
        <template #aside>
          <b-avatar
            ref="previewEl"
            :src="userData.avatar"
            :text="avatarText(userData.name)"
            :variant="`light-primary`"
            size="90px"
            rounded
          />
        </template>
        <div class="d-flex mb-2">
          <b-button
            v-ripple.400="'rgba(255, 206, 38, 0.15)'"
            variant="primary"
            size="sm"
            @click="$refs.refInputEl.click()"
          >
            <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="inputImageRenderer"
            >

            <feather-icon
              icon="EditIcon"
              class="mr-50"
            />
            <span class="align-middle">Update</span>
          </b-button>

          <b-button
            variant="outline-dark"
            size="sm"
            class="ml-1"
            @click="removeAvatar"
          >
            <feather-icon
              icon="TrashIcon"
              class="mr-50"
            />
            <span class="align-middle">Remove</span>
          </b-button>
        </div>
      </b-media>

      <!-- Account Type -->
      <validation-provider
        #default="validationContext"
        name="Account Type"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Account Type"
          label-for="account-type"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="userData.account_type"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="accountTypeOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="account-type"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <!-- Privilege -->
      <validation-provider
        #default="validationContext"
        name="Privilege"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Privilege"
          label-for="privilege"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="userData.privilege"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="privilegeOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="privilege"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <!-- Status -->
      <validation-provider
        #default="validationContext"
        name="Status"
        rules="required"
      >
        <b-form-group
          class="mb-1"
          label="Status"
          label-for="status"
          :state="getValidationState(validationContext)"
        >
          <v-select
            v-model="userData.status"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="statusOptions"
            class="w-100"
            :reduce="val => val.value"
            input-id="status"
          />
          <b-form-invalid-feedback :state="getValidationState(validationContext)">
            {{ validationContext.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BButton, BMedia, BAvatar, BFormGroup, BFormInput, BFormRadioGroup,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import { ValidationProvider } from 'vee-validate'
import { useInputImageRenderer } from '@core/comp-functions/forms/form-utils'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import formValidation from '@core/comp-functions/forms/form-validation'
import useUsersForm from './useUsersForm'
import { statusOptions, userTypeOptions, accountTypeOptions } from '@/utils/computed-properties/system-codes/systemCodesComputedProperties'
import useSystemCodes from '../../system-codes/useSystemCodes'
import usePrivilegesForm from '../../privileges/privileges-form/usePrivilegesForm'

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BMedia,
    BAvatar,
    BFormGroup,
    BFormInput,
    BFormRadioGroup,
    BFormInvalidFeedback,

    vSelect,

    //  Form Validation
    ValidationProvider,
  },

  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },

  setup(props, { emit }) {
    const { callAccountType, callUserType } = useSystemCodes()

    const { privilegeOptions } = usePrivilegesForm()

    const { genderOptions } = useUsersForm()

    callAccountType()
    callUserType()

    const refInputEl = ref(null)
    const previewEl = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => {
      emit('update-avatar', base64)
    })

    const removeAvatar = () => { emit('remove-avatar') }

    const {
      getValidationState,
    } = formValidation()

    return {
      //  Image
      avatarText,
      refInputEl,
      previewEl,
      inputImageRenderer,
      removeAvatar,

      //  Options
      genderOptions,
      userTypeOptions,
      statusOptions,
      accountTypeOptions,
      privilegeOptions,

      //  Form
      getValidationState,
    }
  },
}
</script>
